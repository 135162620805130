import React, { useEffect } from "react";
//import ReactGA from 'react-ga';
import { gtag, install } from 'ga-gtag';

// const TRACKING_ID = "AW-348524255/_4P6CJeLw4IYEN-dmKYB"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
// <!-- Event snippet for Purchase conversion page -->
// <script>
//   gtag('event', 'conversion', {
//       'send_to': 'AW-348524255/_4P6CJeLw4IYEN-dmKYB',
//       'transaction_id': ''
//   });
// </script>



// install('AW-348524255/_4P6CJeLw4IYEN-dmKYB')
// gtag('event', 'conversion', {
//       'send_to': 'AW-348524255/_4P6CJeLw4IYEN-dmKYB',
//           'transaction_id': ''
//     });

const ThankyouPage = () => {
    useEffect(() => {

        install('AW-348524255/_4P6CJeLw4IYEN-dmKYB')
        gtag('event', 'conversion', {
              'send_to': 'AW-348524255/_4P6CJeLw4IYEN-dmKYB',
                  'transaction_id': ''
            });
    }, []);

  return <div>
            <div className="thankyou-page">
                <div className="thankyou-page-inner">
                    <div className="thankyou-sec">
                        <div className="thankyou-sec-inner">
                            <div className="container">
                                <div className="thankyou-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="thankyou-head">
                                                <h2 className="text-center wow animate__fadeInDown">Thankyou</h2>
                                            </div>
                                            <div className="thankyou-img text-center">
                                                <img className="wow animate__zoomIn" data-wow-delay="0.75s" src={require("../Components/images/tik-png.png")}/>
                                            </div>
                                            <div className="thankyou-para">
                                                <p className="text-center wow animate__fadeInLeft" data-wow-delay="1s"><strong>Thank you for Registration my team will get back to you.</strong></p>
                                                {/* <p className="text-center">Please Go through our website for more information. </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
};


export default ThankyouPage;
