import React from "react";
import "./Pleasewait.css"

const Pleasewait = () => {
  return <div>
    <div className="please-wait">
        <div className="please-wait-inner">
            <div className="spinner mb-2">
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>               
            </div>
            <h5 className="text-center mb-4">Loading...please wait..!</h5>
        </div>
    </div>
  </div>;
};

export default Pleasewait;
