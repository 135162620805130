import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="speakers-footer">
        <div className="speakers-footer-inner">
          <div className="container">
            <div
              className="footer-border-content wow animate__zoomIn animated"
              data-wow-delay="1s"
            >
              <div className="content-head">
                <h2 className="text-center">A Promise!</h2>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="letter">
                    <div className="letter-heading">
                      <h5 className="text-white text-center">
                        No Questions Asked Money Back Guarantee
                      </h5>
                    </div>
                    <div className="letter-content">
                      <p className="text-white mb-3">Dear Sir / Ma'am,</p>
                      <p className="text-white mb-3">
                          I am here to help you grow your confidence and make you an influential speaker, 
                          and I'll share with you extremely valuable secrets and insights that have impacted 
                          Million+ of people overall, and I personally ensure everyone who comes and attends
                           my workshops gets more than they expected.
                      </p>
                      <p className="text-white mb-3">
                          After attending the workshop, If you are not satisfied with the course for some reason,
                           I take complete responsibility and ownership of that, I promise to give a complete refund 
                           of the workshop amount of Rs 999. All you have to do is mail to me at 
                           <a href="mailto:saurabh@saurabhchharia.com"> saurabh@saurabhchharia.com </a>{" "}
                           and you will receive all your money back without the hassle, 
                           my team will ensure you are provided with a refund in the shortest amount of time.
                      </p>
                      <p className="text-white mb-2">Your's</p>
                      <p className="text-white">Saurabh Chharia</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
