import React, { useEffect } from "react";
import "./RegisterForm.css";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { SignupSchema, Validation } from "../Validation";
import Pleasewait from "../Pleasewait";
import Modal from "react-modal";
//import ReactGA from 'react-ga';
//import TagManager from 'react-gtm-module'
import { gtag, install } from 'ga-gtag';

const RegisterForm = () => {
  const [loading, setLoading] = useState(false);

  const onSubmi = async (Validation) => {
    install('AW-348524255/jqq1COCcwoIYEN-dmKYB');
    gtag('event', 'conversion', {'send_to': 'AW-348524255/jqq1COCcwoIYEN-dmKYB'});
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json",      
    },
      body: JSON.stringify(Validation),
    };
    
    const response = await fetch(
      "https://backend.saurabhchharia.com/api/reg",
      requestOptions
    ).then(response => (response.json()))
    .then((data) => {
        console.log("resa",data);
        if (data.success) {          
              window.location = "https://www.instamojo.com/@discoverywithin/lefabea4aac19485bb7835d4a1943ec4d/";
              setLoading(false);
            }
     })
     .catch((err) => {
        console.log(err.message);
     });

      // .then((response) => {
      //   response.json();
      //   console.log("response", response);

      //   // if (response.status==200) {          
      //   //   window.location = "https://www.instamojo.com/@discoverywithin/lefabea4aac19485bb7835d4a1943ec4d/";
      //   //   setLoading(false);
      //   // }
      // })
      // .then((data) => {
      //   console.log("data", data);
      //   // Handle data
      // })
      // .catch((err) => {
      //   console.log("err.message", err.message);
      // });
  };
  
  function numbersOnly(e) {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    } else {
      console.log(" You pressed a key: " + key);
    }
  }
  function textOnly(e) {
    var key = e.key;
    var regex = /^[a-zA-Z ]/;
    if (!regex.test(key)) {
      e.preventDefault();
    } else {
      console.log(" You pressed a key: " + key);
    }
  }


// window.location = "https://imjo.in/JrpYD5";


  return (
    <div>
      <Formik
        initialValues={Validation}
        validationSchema={SignupSchema}
        onSubmit={onSubmi}
        render={() => (
          <Form>
            <div className="register-form">
              <div className="register-form-inner">
                <div className="container">
                  <div className="content-head mb-4">
                    <h4 className="text-center text-white mb-3">Key to Mastering Public Speaking Skills</h4>
                    <h2 className="text-center text-white">Register Here</h2>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-md-3 padding right-side-col">
                      <div className="name-field wow animate__fadeInUp">
                        <Field  onKeyPress={(e) => textOnly(e)} className="w-100" type="text" name="name" placeholder="Your Name" />
                        <p className="ms-3 mt-2">
                          <ErrorMessage name="name" />
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-md-3 wow animate__fadeInUp padding1 left-side-col"
                      data-wow-delay="0.5s"
                    >
                      <div className="email-field">
                        <Field
                          className="w-100"
                          type="email"
                          name="email"
                          placeholder="Email Address"
                        />
                        <p className="ms-3 mt-2">
                          <ErrorMessage name="email" />
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-md-3 wow animate__fadeInUp padding1 right-side-col"
                      data-wow-delay="0.75s"
                    >
                      <div className="phone-field">
                        <Field
                          onKeyPress={(e) => numbersOnly(e)}
                          className="w-100"
                          type="tel"
                          maxLength={10}
                          name="mobile"
                          placeholder="Mobile Number"
                        />
                        <p className="ms-3 mt-2">
                          <ErrorMessage name="mobile" />
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-md-2 wow animate__fadeInUp padding2 "
                      data-wow-delay="1s"
                    >
                      <div className="register-form-submit left-side-col">
                        <button type="submit">Submit</button>
                      </div>
                    </div>
                    {loading ? (
                      <Modal
                        isOpen={loading}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww"
                      >
                        <Pleasewait />
                      </Modal>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};



  //   useEffect(() => {
  //     // POST request using fetch inside useEffect React hook
  //     const requestOptions = {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify(Validation)
  //     };
  //     fetch('https://backend.saurabhchharia.com/api/register', requestOptions)
  //       .then((response) => {
  //         response.json()
  //         console.log(response)})
  //       .then((data) => {
  //         console.log(data);
  //         // Handle data
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });

  // }, []);

export default RegisterForm;
