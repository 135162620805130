import React from "react";
import Modal from "react-modal";
import { useState } from "react";
import "./Modalstyles.css";
import CountdownTimer from "./CountdownTimer/CountdownTimer";
import RegisterForm from "./Form/RegisterForm";
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



Modal.setAppElement("#root");


const Content = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const [isOpen2, setIsOpen2] = useState(false);
  function toggleModal2() {
    setIsOpen2(!isOpen2);
  }
  const [isOpen3, setIsOpen3] = useState(false);
  function toggleModal3() {
    setIsOpen3(!isOpen3);
  }
  const [isOpen4, setIsOpen4] = useState(false);
  function toggleModal4() {
    setIsOpen4(!isOpen4);
  }
  const [isOpen5, setIsOpen5] = useState(false);
  function toggleModal5() {
    setIsOpen5(!isOpen5);
  }
  const [isOpen6, setIsOpen6] = useState(false);
  function toggleModal6() {
    setIsOpen6(!isOpen6);
  }
  const [isOpen7, setIsOpen7] = useState(false);
  function toggleModal7() {
    setIsOpen7(!isOpen7);
  }
  const THREE_DAYS_IN_MS = 1 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  const arrow = ">>>"

  var settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay : true,
    arrows : true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            infinite: true,
            }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]

    // nextArrow: ,
    // prevArrow:,
    };
  return (

    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Header />
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        overlayClassName="myoverlay"
        className="mymodal ww"
      >
        <div className="modal-register-form">
          <div className="close-button">
            <button
              type="close"
              className="text-danger"
              onClick={toggleModal}
            >
              X
            </button>
          </div>
          <RegisterForm />
        </div>
      </Modal>

      <div className="site-content">
        <div className="site-content-inner">
          <div className="content-sec-1">
            <div className="content-sec-1-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center">What Happens When You Join?</h2>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="image-blk wow animate__ animate__fadeInLeftBig animated"     >
                      <img
                        src={require("../Components/images/image-blk-img.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="valpoints-blk">
                      <div
                        className="point-1 d-flex align-items-center wow animate__fadeInDown">
                        <i className="fa fa-check" aria-hidden="true"></i>
                        <p className="text-white">
                          You can <span>Express yourself with confidence</span>{" "}
                          without any fear of anyone
                        </p>
                      </div>
                      <div className="point-2 d-flex align-items-center wow animate__fadeInDown" data-wow-delay="0.5s">
                        <i className="fa fa-check" aria-hidden="true"></i>
                        <p className="text-white">
                          You can handle any{" "}
                          <span>presentation or speech confidently</span>{" "}
                          <br /> even though you are not prepared
                        </p>
                      </div>
                      <div
                        className="point-3 d-flex align-items-center wow animate__fadeInDown"
                        data-wow-delay="0.5s"
                      >
                        <i className="fa fa-check" aria-hidden="true"></i>
                        <p className="text-white">
                          You can experience{" "}
                          <span>Career Growth with Promotions</span>
                          <br /> and onsite opportunities as you become
                          confident
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-sec-2">
            <div className="content-sec-2-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center"><span className="become-text">Want to be an Impactful Speaker? </span> <br/> I’ll Be Your Coach! </h2>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="detail-blk wow animate__ animate__fadeInLeftBig  animate__slow  animated">
                      <div className="detail-1">
                        <p className="text-center text-white">
                          STARTS ON{" "}
                          <span className="mx-1">11th DECEMBER 2022</span>{" "}
                          {/* (10:00 AM - 12:30 PM) */}
                        </p>
                      </div>
                      <div className="detail-2">
                        <p className="text-center text-white">
                          Language - Basic English
                        </p>
                      </div>
                      <div className="detail-3 d-flex justify-content-center">
                        <button className="text-white" onClick={toggleModal}>
                          Join Now
                        </button>
                      </div>
                      <div className="detail-4">
                        <p className="text-center text-white">
                            Register In the Next 15:00 Mins
                        </p>
                      </div>
                      <CountdownTimer targetDate={dateTimeAfterThreeDays} />
                      {/* <div className="detail-5 d-none justify-content-evenly align-items-center">
                                            <div className="circles"><p className="text-center text-white">01 <br /> <span>Day</span></p></div>
                                            <div className="circles"><p className="text-center text-white">14 <br /> <span>Hours</span></p></div>
                                            <div className="circles"><p className="text-center text-white">28 <br /> <span>Minutes</span></p></div>
                                            <div className="circles"><p className="text-center text-white">14 <br /> <span>Seconds</span></p></div>
                                        </div> */}
                      {/* <div className="detail-6">
                        <p className="text-center text-white">
                          To Unlock Bonuses Worth Rs 6,487
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="coach-blk wow animate__ animate__fadeInRightBig animated">
                      <div className="coach-img text-center">
                        <img
                          src={require("../Components/images/detail-blk-img.png")}
                          alt=""
                        />
                      </div>
                      <div className="coach-details">
                        <h2 className="text-center">SAURABH CHHARIA</h2>
                        <p className="text-center text-white">
                          Trained over<span> 1,00,000 </span>
                          Professionals in Public Speaking <br />
                          in the last 10 years
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-sec-3">
            <div className="content-sec-3-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center">
                    Saurabh's Clients Work for Companies Like
                  </h2>
                </div>
                <div className="row">
                  <div className="col-md-2 col-sm-4">
                    <div className="companies text-center wow animate__fadeInUp">
                      <img
                        src={require("../Components/images/tcs.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-4">
                    <div
                      className="companies text-center wow animate__fadeInUp"
                      data-wow-delay="0.25s"
                    >
                      <img
                        src={require("../Components/images/microsoft.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-4">
                    <div
                      className="companies text-center wow animate__fadeInUp"
                      data-wow-delay="0.5s"
                    >
                      <img
                        src={require("../Components/images/google.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-4">
                    <div
                      className="companies text-center wow animate__fadeInUp"
                      data-wow-delay="0.75s"
                    >
                      <img
                        src={require("../Components/images/amazon.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-4">
                    <div
                      className="companies text-center wow animate__fadeInUp"
                      data-wow-delay="1s"
                    >
                      <img
                        src={require("../Components/images/infosys.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-4">
                    <div
                      className="companies text-center wow animate__fadeInUp"
                      data-wow-delay="1.25s"
                    >
                      <img
                        src={require("../Components/images/accenture.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-sec-4">
            <div className="content-sec-4-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center">
                    What will change in you as a person?
                  </h2>
                </div>
                <div className="row wow animate__zoomIn">
                  <div className="col-md-8">
                    <div className="chart-image text-end">
                      <p className="text-start text-white second-point">
                        Right tools and techniques
                      </p>
                      <img
                        src={require("../Components/images/chart-blkimg.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="chart-point1">
                      <p className="text-white first-point">
                         Right mindset to speak on stage
                      </p>
                    </div>
                    <div className="chart-point2">
                      <p className="text-white third-point">
                          Practical exposure to stage
                      </p>
                    </div>
                  </div>
                </div>
                <div className="chart-join d-flex justify-content-center">
                  <button className="text-white" onClick={toggleModal}>
                    Join Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content-sub-4">
            <div className="content-sub-4-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center">
                    What will change in you as a person?
                  </h2>
                </div>
                <div className="triangle-blk">
                  {/* <div className="row">
                                    <div className="col-md-12">
                                        <div className="rectangle-1 similar-rect">
                                            <p className="text-white"><span className="me-4">1</span>Right Mindset to Speak on Stage</p>
                                        </div>
                                        <div className="rectangle-2">
                                            <p className="text-white"><span className="me-4">2</span>Right Tools and Techniques</p>
                                        </div>
                                        <div className="rectangle-3 similar-rect">
                                            <p className="text-white"><span className="me-4">3</span>Practical Exposure to stage</p>
                                        </div>
                                    </div>
                                </div> */}
                  <div className="row wow animate__zoomIn">
                    <div className="col-md-12">
                      <div className="triangle-responsive-img">
                        <img
                          src={require("../Components/images/responsive-triangle.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="chart-join d-flex justify-content-center">
                    <button className="text-white" onClick={toggleModal}>
                      Join Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-sec-5">
            <div className="content-sec-5-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center">
                    Who this workshop will help the best?
                  </h2>
                </div>
                <div className="row categories-blk align-items-center justify-content-center text-center">
                  <div className="col-md-3 col-sm-3">
                    <div className="profession-type">
                      <div className="profession-inner">
                        <div className="same-type-pro shadow profe-1 wow animate__fadeInUp">
                          <h5>Corporate <br /> Professionals</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="profession-type">
                      <div className="profession-inner">
                        <div className="same-type-pro shadow profe-2 wow animate__fadeInUp" data-wow-delay="0.10s">
                          <h5>Politicians</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="profession-type">
                      <div className="profession-inner">
                        <div className="same-type-pro shadow profe-3 wow animate__fadeInUp" data-wow-delay="0.20s">
                          <h5>Teachers</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="profession-type">
                      <div className="profession-inner">
                        <div className="same-type-pro shadow profe-4 wow animate__fadeInUp" data-wow-delay="0.30s">
                          <h5>Aspiring Trainers</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="profession-type">
                      <div className="profession-inner">
                        <div className="same-type-pro shadow profe-5 wow animate__fadeInUp" data-wow-delay="0.40s">
                          <h5>Lawyers</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="profession-type">
                      <div className="profession-inner">
                        <div className="same-type-pro shadow profe-6 wow animate__fadeInUp" data-wow-delay="0.50s">
                          <h5>Doctors</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="profession-type">
                      <div className="profession-inner">
                        <div className="same-type-pro shadow profe-7 wow animate__fadeInUp" data-wow-delay="0.60s">
                          <h5>Bankers</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="profession-type">
                      <div className="profession-inner">
                        <div className="same-type-pro shadow profe-8 wow animate__fadeInUp" data-wow-delay="0.70s">
                          <h5>Business Owners</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="three-points-left wow animate__fadeInLeft">
                      <img className="first-3-img" src={require("../Components/images/dont-join.png")} alt="" />
                      <div className="image-resp">
                        <img src={require("../Components/images/responsive-circles-img.png")} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="three-points-right">
                      <div
                        className="img-point1 growth-blk d-flex align-items-center wow animate__fadeInRight"
                        data-wow-delay="0.25s"
                      >
                        <div className="number1 number-marg">
                          <p className="text-white">1</p>
                        </div>
                        <div className="text1 second-text">
                          <p>
                            You are not serious about your <br /> career
                            growth
                          </p>
                        </div>
                      </div>
                      <div
                        className="img-point2 growth-blk d-flex align-items-center wow animate__fadeInRight"
                        data-wow-delay="0.5s"
                      >
                        <div className="number2 number-marg">
                          <p className="text-white">2</p>
                        </div>
                        <div className="text2 second-text">
                          <p>You are not an action taker</p>
                        </div>
                      </div>
                      <div
                        className="img-point3 growth-blk d-flex align-items-center wow animate__fadeInRight"
                        data-wow-delay="0.75s"
                      >
                        <div className="number3 number-marg">
                          <p className="text-white">3</p>
                        </div>
                        <div className="text3 second-text">
                          <p>
                            You never aspire to be called a <br /> great
                            speaker
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="register-now d-flex justify-content-center">
                  <button
                    className="text-white wow animate__ animate__backInDown"
                    data-wow-delay="1s"
                    onClick={toggleModal}
                  >
                    Register now at <span className="me-1 no-fonter">₹</span>{" "}
                    999 only
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content-sec-6">
            <div className="content-sec-6-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center">Unable to decide?</h2>
                  <p className="text-center text-white">
                      Listen to people whom I have helped to become great speakers.
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="youtube1 text-center wow animate__fadeInUp img-up-tra">
                      <Modal isOpen={isOpen2}
                        onRequestClose={toggleModal2}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/DPK8b1N4zZg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </Modal>
                      <img onClick={toggleModal2} src={require("../Components/images/youtube-1.png")} alt="" />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="youtube1 text-center wow animate__fadeInUp img-up-tra">
                      <Modal isOpen={isOpen3}
                        onRequestClose={toggleModal3}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/cBWlBidHtb0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </Modal>
                      <img onClick={toggleModal3} src={require("../Components/images/youtube-2.png")} alt="" />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="youtube1 text-center wow animate__fadeInUp img-up-tra">
                      <Modal isOpen={isOpen4}
                        onRequestClose={toggleModal4}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/5RPLVyjV96E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </Modal>
                      <img onClick={toggleModal4} src={require("../Components/images/youtube-3.png")} alt="" />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="youtube text-center wow animate__fadeInDown img-up-tra">
                      <Modal isOpen={isOpen5}
                        onRequestClose={toggleModal5}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/Cg7UYmOuW-c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </Modal>
                      <img onClick={toggleModal5} src={require("../Components/images/youtube-4.png")} alt="" />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="youtube text-center wow animate__fadeInDown img-up-tra">
                      <Modal isOpen={isOpen6}
                        onRequestClose={toggleModal6}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/2BUZGDhhoK0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </Modal>
                      <img onClick={toggleModal6} src={require("../Components/images/youtube-5.png")} alt="" />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="youtube text-center wow animate__fadeInDown img-up-tra">
                      <Modal isOpen={isOpen7}
                        onRequestClose={toggleModal7}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/2bWPS-DzsXY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </Modal>
                      <img onClick={toggleModal7} src={require("../Components/images/youtube-6.png")} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-sec-7">
            <div className="content-sec-7-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center">Testimonials</h2>
                </div>
                <div className="testimonial-block">
                  <div className="row justify-content-center">
                  <Slider {...settings}>
                    <div className="col-md-4 text-center wow animate__fadeInDown">                      
                      <div className="testimonial-1 testimonial-back">
                          <div className="testimonial-back-inner">
                            <div className="test-image">
                              <img className="reviewer-position" src={require("../Components/images/reviewer1.png")} alt="" />
                            </div>
                            <div className="star-blk d-flex justify-content-center align-items-center">
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                            </div>
                            <div className="reviewer">
                              <h4 className="text-center text-white">Shwetha </h4>
                            </div>
                            <div className="review">
                              <p>I had a really great experience after I joined the course to work on my
                                 public speaking skills, and I must say that their training method is completely 
                                 out of the box, and quite practical.  Most importantly the mentor Saurabh Chharia 
                                 gave me feedback in every session through which I was successfully able to improve 
                                 and keep a track of the same at the same time. I really recommend using their public
                                  speaking course. Overall the experience was phenomenal
                              </p>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center wow animate__fadeInDown" data-wow-delay="0.25s">                      
                      <div className="testimonial-2 testimonial-back">
                            <div className="testimonial-back-inner">
                              <div className="test-image">
                                <img className="reviewer-position" src={require("../Components/images/reviewer2.png")} alt="" />
                              </div>
                              <div className="star-blk d-flex justify-content-center align-items-center">
                                <div className="stars">
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div className="stars">
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div className="stars">
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div className="stars">
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div className="stars">
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                              </div>
                              <div className="reviewer">
                                <h4 className="text-center text-white">
                                  Mr Nagaprasad
                                </h4>
                              </div>
                              <div className="review">
                                <p>
                                  {" "}
                                  I got an excellent experience with Saurabh Chharia Masterclass.
                                   It was an amazing 2 full-day session. I can see the transformation 
                                   within me, where I was before attending the session and after completing
                                    the session. 
                                    {/* I attended with a lot of expectations and questions in my mind
                                     about the possibility of becoming a public speaker in just 2 days */}
                                </p>
                              </div>
                            </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center wow animate__fadeInDown" data-wow-delay="0.5s">                      
                      <div className="testimonial-3 testimonial-back">
                          <div className="testimonial-back-inner">
                            <div className="test-image">
                              <img src={require("../Components/images/reviewer3.png")} alt=""/>
                            </div>
                            <div className="star-blk d-flex justify-content-center align-items-center">
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                            </div>
                            <div className="reviewer">
                              <h4 className="text-center text-white">
                                Vivek Dubey
                              </h4>
                            </div>
                            <div className="review">
                              <p>
                                5-star rating for Saurabh Chhharia master classes.
                               I never believed in magic, I thought it is just a trick... 
                               But this time I believed because it happened to me in Saurabh’s 
                               Professional Public speaking Training. Thanks to him for helping me
                               throughout my public speaking training. You will see a great transformation in you.
                              </p>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center wow animate__fadeInDown" data-wow-delay="0.75s">                      
                      <div className="testimonial-4 testimonial-back">
                              <div className="testimonial-back-inner">
                                <div className="test-image">
                                  <img className="reviewer-position" src={require("../Components/images/review4.png")} alt=""/>
                                </div>
                                <div className="star-blk d-flex justify-content-center align-items-center">
                                  <div className="stars">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                  </div>
                                  <div className="stars">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                  </div>
                                  <div className="stars">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                  </div>
                                  <div className="stars">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                  </div>
                                  <div className="stars">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                  </div>
                                </div>
                                <div className="reviewer">
                                  <h4 className="text-center text-white">
                                    {" "}
                                    Anushka{" "}
                                  </h4>
                                </div>
                                <div className="review d-flex align-items-center">
                                  <p>
                                      I'm Anushka. I really appreciated your knowledge and efforts
                                       to transform the lives of many through your courses. I highly
                                        recommended you choose these courses and become an influential
                                         Public speaker
                                  </p>
                                </div>
                              </div>
                        </div>
                    </div>
                    <div className="col-md-4 text-center wow animate__fadeInDown" data-wow-delay="1s">                     
                      <div className="testimonial-5 testimonial-back">
                          <div className="testimonial-back-inner">
                              <div className="test-image">
                                <img src={require("../Components/images/NAGARAJ.png")} alt=""/>
                              </div>
                              <div className="star-blk d-flex justify-content-center align-items-center">
                                <div className="stars">
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div className="stars">
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div className="stars">
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div className="stars">
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div className="stars">
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                              </div>
                              <div className="reviewer">
                                <h4 className="text-center text-white">
                                  NAGARAJ CHINNA
                                </h4>
                              </div>
                              <div className="review">
                                <p>
                                    I would really like to thank you Saurabh sir,
                                     I had an amazing experience in association with you ...
                                     He is a Public speaker, trainer, Coach, and a great human 
                                     being, He gives us positivity with his behavior & excellent support 
                                     ...Keep going, Saurab Sir...
                                </p>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center wow animate__fadeInDown" data-wow-delay="1.25s">                      
                      <div className="testimonial-6 testimonial-back">
                        <div className="testimonial-back-inner">
                            <div className="test-image">
                              <img src={require("../Components/images/review6.png")} alt=""/>
                            </div>
                            <div className="star-blk d-flex justify-content-center align-items-center">
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <div className="stars">
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                            </div>
                            <div className="reviewer">
                              <h4 className="text-center text-white">
                                Mr Vikram
                              </h4>
                            </div>
                            <div className="review">
                              <p>
                                  Deserves 5-star. Saurabh is extremely professional. 
                                  I like the methods he uses. It's a wonderful journey 
                                  with him and his associates along with new aspirants 
                                  in public speaking. I enjoyed the course and am very 
                                  proud to be part of this academy.
                              </p>
                            </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-sec-8">
            <div className="content-sec-8-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center text-white">Meet Your Mentor</h2>
                  <h3 className="text-center text-white">SAURABH CHHARIA</h3>
                </div>
                <div className="saurabh-image text-center mt-4 wow animate__zoomIn">
                  <img
                    src={require("../Components/images/saurabh.png")}
                    alt=""
                  />
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-3 col-sm-4">
                    <div className="skills text-center merge-down wow animate__fadeInDown">
                      <img
                        src={require("../Components/images/icon-1.png")}
                        alt=""
                      />
                      <p className="text-center text-white">
                        <span>10</span> Years <br /> of Experience in Training{" "}
                        <br /> and Coaching
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4">
                    <div
                      className="skills text-center merge-down wow animate__fadeInDown"
                      data-wow-delay="0.25s"
                    >
                      <img
                        src={require("../Components/images/icon-2.png")}
                        alt=""
                      />
                      <p className="text-center text-white">
                        <span>300+</span> Seminars <br />
                        {/* Conducted in the <br /> Last 5 Years */}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4">
                    <div
                      className="skills text-center merge-down wow animate__fadeInDown"
                      data-wow-delay="0.5s"
                    >
                      <img
                        src={require("../Components/images/icon-3.png")}
                        alt=""
                      />
                      <p className="text-center text-white">
                        <span>1000+</span> <br /> Coaching Clients
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4">
                    <div
                      className="skills text-center no-border merge-down wow animate__fadeInDown"
                      data-wow-delay="0.75s"
                    >
                      <img
                        src={require("../Components/images/icon-4.png")}
                        alt=""
                      />
                      <p className="text-center text-white">
                        Josh Talk Speaker
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4">
                    <div
                      className="skills text-center wow animate__fadeInDown"
                      data-wow-delay="1s"
                    >
                      <img
                        src={require("../Components/images/icon-5.png")}
                        alt=""
                      />
                      <p className="text-center text-white">
                        Works with Celebrities <br />
                        and Politicians
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4">
                    <div
                      className="skills text-center wow animate__fadeInDown"
                      data-wow-delay="1.25s"
                    >
                      <img
                        src={require("../Components/images/icon-6.png")}
                        alt=""
                      />
                      <p className="text-center text-white">
                        Author of a Book
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4">
                    <div
                      className="skills skiller no-rh-border text-center wow animate__fadeInDown"
                      data-wow-delay="1.5s"
                    >
                      <img
                        src={require("../Components/images/icon-7.png")}
                        alt=""
                      />
                      <p className="text-center text-white">
                          Millions of views on <br/> YouTube videos
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-sec-9">
            <div className="content-sec-9-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center">
                    I am on a mission to make people speak confidently and
                    comfortably without any hesitation or fear even if they
                    haven't done it before in their life.
                  </h2>
                </div>
                <div className="row justify-content-center after-474-novis">
                  <div className="col-md-4 col-sm-6">
                    <div className="group-images text-center down-marg wow animate__fadeInUp">
                      <img
                        src={require("../Components/images/group-1.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="group-images text-center down-marg wow animate__fadeInUp">
                      <img
                        src={require("../Components/images/group-2.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="group-images text-center down-marg wow animate__fadeInUp">
                      <img
                        src={require("../Components/images/group-3.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="group-images text-center wow animate__fadeInDown">
                      <img
                        src={require("../Components/images/group-4.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="group-images text-center wow animate__fadeInDown">
                      <img
                        src={require("../Components/images/group-5.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="group-images text-center wow animate__fadeInDown">
                      <img
                        src={require("../Components/images/group-6.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="after-474-vis text-center">
                      <img src={require("../Components/images/saurabh-collage-1.png")} alt="" />
                </div>
                <div className="after-375-vis text-center">
                      <img src={require("../Components/images/saurabh-collage-2.png")} alt="" />
                </div>
                {/* <div className="content-head2">
                  <h2 className="text-center">
                    Because I have been a bad Speaker myself in the past
                  </h2>
                </div> */}
              </div>
            </div>
          </div>
          <div className="content-sec-10">
            <div className="content-sec-10-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-center">
                      What top topics will be covered
                  </h2>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-4 col-sm-6">
                    <div className="message-blk text-center down-gap wow animate__fadeInUp">
                      <img
                        src={require("../Components/images/new-icon-1.png")}
                        alt=""
                      />
                      <p className="text-white text-center">
                      Overcoming <br/> Hesitation <br/> And Fear
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div
                      className="message-blk text-center down-gap wow animate__fadeInUp"
                      data-wow-delay="0.5s"
                    >
                      <img
                        src={require("../Components/images/new-icon-2.png")}
                        alt=""
                      />
                      <p className="text-white text-center">
                        Structuring <br />
                        Content
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div
                      className="message-blk text-center down-gap wow animate__fadeInUp"
                      data-wow-delay="0.75s"
                    >
                      <img
                        src={require("../Components/images/new-icon-3.png")}
                        alt=""
                      />
                      <p className="text-white text-center">
                        Body <br />
                        Language
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div
                      className="message-blk text-center wow animate__fadeInUp"
                      data-wow-delay="1s"
                    >
                      <img
                        src={require("../Components/images/new-icon-4.png")}
                        alt=""
                      />
                      <p className="text-white text-center">
                        Voice <br />
                        Modulation <br />
                        Secrets
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div
                      className="message-blk text-center wow animate__fadeInUp"
                      data-wow-delay="1.25s"
                    >
                      <img
                        src={require("../Components/images/new-icon-5.png")}
                        alt=""
                      />
                      <p className="text-white text-center">
                        Confidence
                        <br /> Secrets
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pricing">
                  <h5 className="text-center text-white wow animate__ animate__fadeInLeftBig">
                    Regular Price{" "}
                    <span className="text-danger ms-5">
                      <span className="me-1 no-fonter">₹</span> 10,000
                    </span>
                  </h5>
                  <h5
                    className="text-center text-white wow animate__fadeIn"
                    data-wow-delay="1s"
                  >
                    Today's Price{" "}
                    <span className="green-color ms-5">
                      <span className="me-1 no-fonter">₹</span> 999
                    </span>
                  </h5>
                </div>
                <div className="sec-10-register d-flex justify-content-center">
                  <button
                    className="text-white wow animate__ animate__backInDown"
                    data-wow-delay="0.5s"
                    onClick={toggleModal}
                  >
                    Register now at <span className="ms-1 no-fonter">₹</span>{" "}
                    999 only
                  </button>
                </div>
              </div>
            </div>
          </div>
          <RegisterForm />
          <div className="content-sec-11">
            <div className="content-sec-11-inner">
              <div className="container">
                <div className="content-head3">
                  <h2 className="text-center text-white wow animate__jackInTheBox">
                    Still not sure? We got your back!
                  </h2>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="left-content-blk wow animate__fadeInLeft"
                      data-wow-delay="0.5s"
                    >
                      <h5>We Guarantee! </h5>
                      <p className="text-white">
                        Hurry Up! Get this amazing offer today at just Rs 999, 
                        you will definitely appreciate the course and get a money-back guarantee.
                        Join today, go through our seminar and if you don't like it for any reason,
                        simply send us an email and we'll refund every penny – no questions asked.
                      </p>
                      <button
                        className="text-white wow animate__ animate__backInDown"
                        data-wow-delay="1s"
                        onClick={toggleModal}
                      >
                        YES! Save My Spot Now {arrow}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="gurantee-blk wow animate__fadeInRight"
                      data-wow-delay="0.5s"
                    >
                      <img
                        src={require("../Components/images/guarantee.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Content;
