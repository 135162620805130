import React from "react";
import RegisterForm from "./Form/RegisterForm";

const Header = () => {
    
  return <div>
        <div className="speakers-header">
            <div className="header-inner">
                <div className="container">
                    <div className="site-logo">
                        <div className="site-img text-center mb-4 wow animate__ animate__fadeInUp  animate__slow animated">
                            <img src={require("../Components/images/site-logo.png")} alt="" />
                        </div>
                    </div>
                    <div className="head-sec-1">
                        <div className="site-heading">
                            <h1 className="text-center wow animate__ animate__backInDown animate__slow" data-wow-delay="0.5s">CONFIDENT SPEAKERS BLUEPRINT</h1>
                        </div>
                    </div>
                    <div className="head-sec-2-out d-flex justify-content-center">
                        <div className="head-sec-2 wow animate__zoomIn animated" data-wow-delay="1.5s">
                            <h3 className="text-center">6 Hours of Physical Classroom Training</h3>
                            <p className="text-center text-white">Join us and become a successful leader by <br/>
                                    <span>mastering public speaking</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RegisterForm/>
  </div>;
};

export default Header;
