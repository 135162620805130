import React from 'react';

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? 'circles danger' : 'circles'}>
      <p className="text-center text-white">
      {value}<br/>
      <span>{type}</span>
      </p>
    </div>
  );
};

export default DateTimeDisplay;
