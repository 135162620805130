import * as Yup from 'yup';
export const SignupSchema = Yup.object().shape({    
    name : Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Your Name Required'),
    email: Yup.string().email('This email does not Exists').required('Your Email Required'),
    mobile : Yup.string().min(10 , 'Phone number must be 10 numbers').max(10 , 'Phone number must be 10 numbers').required('Mobile Number Required'),
});

export const Validation = {
    name : "" ,
    email : "" ,
    mobile : "",
}