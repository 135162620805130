import './App.css';
import Content from './Components/Content';
import ThankyouPage from './Components/ThankyouPage';
import { BrowserRouter as Router, Route , Routes} from "react-router-dom"
// import ThankyouPage from './Components/ThankyouPage';

function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route exact path='/' element={<Content/>} />
            <Route path='/thank-you' element={<ThankyouPage/>} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
